<template>
  <div>
    <el-form label-width="140px" v-if="isShow" class="form-con-center2">
      <el-form-item label="团队/平台名称">{{list.teamsName}}</el-form-item>
      <el-form-item label="访问地址">
        <el-button type="text" @click="showDialog">查看</el-button>
      </el-form-item>
      <el-form-item label="Logo">
        <img :src="list.avatar" alt="" style="width:150px;height:150px">
      </el-form-item>
      <el-form-item label="团队简介">{{list.content}}</el-form-item>
      <el-form-item label="是否开通商城">
        <el-switch disabled v-model="list.isShop"></el-switch>
        <br>
      </el-form-item>
      <el-form-item label="商城地址" v-if="list.shopUrl">
        {{list.shopUrl}}
        <el-button type="text" @click="seccendCopy(list.shopUrl)">复制</el-button>
      </el-form-item>
      <el-form-item label="团队管理员">{{list.userName}}</el-form-item>
      <el-form-item label="专属公众号" >
        <el-switch disabled v-model="list.isApp"> </el-switch>
        <img v-if="gzhpic" :src="gzhpic" class="avatar">
      </el-form-item>
      <el-form-item label="底部申明">{{list.bottomStatement}}</el-form-item>
      <!-- <el-form-item label="背景图设置">
        <div class="bgimg">
          <div>
            <p>医生PC端背景图片</p>
            <img src="" alt="" />
          </div>
          <div>
            <p>医生移动端背景图片</p>
            <img src="" alt="" />
          </div>
          <div>
            <p>患者移动端背景图片</p>
            <img src="" alt="" />
          </div>
        </div>
      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="edit">编辑</el-button>
      </el-form-item>
    </el-form>
    <EditViewVue v-show="!isShow" :list="list" :isShow="isShow" @submit="getisShow"/>
    <el-dialog title="平台访问地址" :visible.sync="dialogVisible" width="30%">
      <p>患者手机端</p>
      <el-input v-model="input1" disabled style="width: 80%; margin-top: 10px"></el-input>
      <el-button @click="seccendCopy(input1)">复制</el-button>
      <p style="margin-top: 10px">医生PC端</p>
      <el-input v-model="input2" disabled style="width: 80%; margin-top: 10px"></el-input>
      <el-button @click="seccendCopy(input2)">复制</el-button>
      <p style="margin-top: 10px">医生手机端</p>
      <el-input v-model="input3" disabled style="width: 80%; margin-top: 10px"></el-input>
      <el-button @click="seccendCopy(input3)">复制</el-button>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import EditViewVue from "./EditView.vue";
export default {
  components: {
    EditViewVue,
  },

  data() {
    return {
      list: {},
	  gzhpic:'',
      isShow: true,
      dialogVisible: false,
      //测试
      input1:  "",
     // input1: "https://ky.docteams.cn/patient/#/login",
     input2: "https://ky.docteams.cn/#/login",
      //测试
      input3:''
    //  input3: "https://ky.docteams.cn/doctor/#/login",
    };
  },

  mounted() {
	  this.input1 = this.$global.dominPatient+'#/login'
	    this.input3 = this.$global.dominDoctor+'#/login'

    this.getList();
    // this.getpic()
  },

  methods: {
    getisShow(val) {
      this.getList();
      this.isShow = val;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    seccendCopy(val) {
      this.$copyText(val).then(() => {
        this.$message.success("复制成功");
      });
    },
    getList() {
      // this.$http({
      //       url: this.$http.adornUrl("/sysPic/listPic"),
      //       method: "get",
      //       params: {
      //         actionId: '14d585b6fb3c43d3a2efed677e986de4',
      //         picType:20,
      //         pageNum:1,
      //         pageSize:999
      //       }
      //     })
      this.$http({
        url: this.$http.adornUrl("/teamsManage/list"),
        method: "get",
        params: {
          id: JSON.parse(localStorage.currentTeam).id,
        },
      }).then((res) => {
        if (res.data.status) {
          this.list = res.data.data[0];
          if (this.list.isShop == 1) {
            this.list.isShop = true;
          } else {
            this.list.isShop = false;
          }
          if (this.list.isApp == 1) {
            this.list.isApp = true;
		this.getsyspic()
          } else {
            this.list.isApp = false;
          }
        }
        // this.creatQrCode()
        console.log(this.list);
      });
    },
	getsyspic() {
	  let that = this;
	  let params = {
	    actionId: JSON.parse(localStorage.currentTeam).id  ,
	    picType: 31, //慢病上传文件类型
	  
	  };
	  console.log(params)
	  that
	    .$http({
	      url: that.$http.adornUrl("/sysPic/listPic"),
	      params: params,
	      method: "get",
	      headers: {
	        "Content-Type": "application/json",
	        token: this.$cookie.get("token")
	      }
	    })
	    .then(({ data }) => {
	      if (data.status) {
	        if (data.status) {
	          this.gzhpic = data.data[0].picUrl
	        }
	      }
	    });
	},
    // 获取背景图片
    getpic() {
      this.$http({
        url: this.$http.adornUrl("/sysPic/listPic"),
        method: "get",
        params: {
          actionId: JSON.parse(localStorage.currentTeam).id,
          picType: 3,
        },
      });
    },
    edit() {
      this.isShow = false;
    },
    //生成二维码
    /* creatQrCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: 'https://www.baidu.com/', // 二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000',
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      });
    } */
  },
};
</script>

<style lang="scss" scoped>
.qr-code {
  background-color: #fff;
}
.bgimg {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
</style>